import React from 'react';

//Ant Design Elements
import Button from "antd/lib/button";
import Modal from "antd/lib/modal";
import Table from "antd/lib/table";

import config from '../../../config';
import api from '../../../helpers/api';
import decode from '../../../helpers/decode';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUserCheck } from '@fortawesome/free-solid-svg-icons';
import Spinner from '../../elements/Spinner';

import { GoogleOAuthProvider } from '@react-oauth/google';
import { GoogleLogin } from '@react-oauth/google';

class GoogleLoginButton extends React.Component {

    state = {
        multipleUsersModalVisible: false,
        multipleUsers: {},
        singleUserModalVisible: false,
        singleUser: {},
        noUserModalVisible: false,
        loading: false,
        connecting: false,
        savingStudent: false
    }
    
    googleProfile = null;
    googleAuth = null;

    modalToggle = (modal_name) => {
        this.setState({
            [modal_name + "ModalVisible"]: !this.state[modal_name + "ModalVisible"]
        });
    };

    /**
     * Create a new Student account with returned FB details.
     * The Student Refresh script will then populate everything else we need.
     */
    createNewGoogleStudent = () => {
        let values = {};
        values.email = this.googleProfile.email;
        values.first_name = this.googleProfile.given_name;
        values.last_name = this.googleProfile.family_name;
        values.google_user_id = this.googleProfile.sub;
        values.source = 'app';

        return api.post("students/save/google", {
            body: JSON.stringify(values)
        })
        .then(res => {
            //Convert to JSON in case we've received a string response
            if (typeof res === 'string') {
                res = JSON.parse(res);
            }
            //Check for an error response (status of "NOK")
            if (res.status === 'NOK' || res.result.success === false) {
                this.setState({
                    showErrorMessage: true,
                    loading: false
                });
            } else {
                // Save the access token
                this.props.cookies.set(config.environmentShort + 'AT', res.result.access_token, {path: '/', domain: config.siteDomain});
                // Update state
                this.props.setAppStateValue('user', {id: res.result.student_id});
                this.props.sendPageviewToGoogleAnalytics('/virtual/bookings-new/user-details/login'); //First ping to signal login
                this.props.sendEventToGoogleTagManager({
                    'event': 'user-signup-google'
                });
                // Smartlook - Track User Registration with Google
                if (window.location.hostname === 'app.ltrent.com.au' && typeof(smartlook) !== 'undefined' && typeof(smartlook) === 'function') {
                    /*global smartlook*/ // This line prevents the below throwing an error when not on production
                    smartlook('track', 'user', {
                        "type": "registration-google"
                    });
                }

                this.props.setAppStateValue('googleUserId', values.google_user_id, false, () => {
                    this.props.setAppStateValue('connectedWithGoogle', true);
                });

                // Start Student TimeStamp Polling
                this.props.startStudentTimeStampChecker();
            }
        })
        .catch(error => {
            this.setState({
                showErrorMessage: true,
                loading: false
            });
        });

    };

    welcomeBack = (
        <div>
            <p style={{fontWeight: 'bold'}}>Welcome back!</p>
            <p>To ensure that we re-connect you to your previous driver trainer, and that your personalised experience is guaranteed, we need to clear your reservations.</p>
            <p>If you are having trouble logging in or registering, please <a href={config.publicSiteUrl + "/locations-and-contact-us/"} target="_blank" rel="noreferrer">contact our office</a>.</p>
        </div>
    );

    // Selecting a User from found Google Users list
    selectUser = async (record) => {
        if (this.props.context === 'Log In') {
            await this.setState({connecting: true}, async () => {
                // Connect the User to this Google Login, then login as the user
                await api.post("students/connectGoogle",{
                    body: JSON.stringify({
                        student_id: record.id,
                        email: this.googleProfile.email,
                        google_user_id: this.googleProfile.sub,
                        google_auth_id_token: this.googleAuth
                    })
                })
                .then(res => {
                    //Convert to JSON in case we've received a string response
                    if (typeof res === 'string') {
                        res = JSON.parse(res);
                    }
                    //Check for an error response (status of "NOK")
                    if (res.status === 'NOK' || res.result.success === false) {
                        this.setState({
                            showErrorMessage: true,
                            errorMessage: res.result.error
                        });
                    } else {
                        this.props.loginSocialMediaUser(res, 'google');
                    }
                });
            });
        } else {
            // If they're trying to register, reset their status and then log them in.
            Modal.error({
                icon: false,
                title: false,
                content: this.welcomeBack,
                onOk: async () => {
                    // Empty cart / bookings
                    this.props.basicStateReset(async () => {
                        // Connect the User to this Google Login, then login as the user
                        await api.post("students/connectGoogle", {
                            body: JSON.stringify({
                                student_id: record.id,
                                email: this.googleProfile.email,
                                google_user_id: this.googleProfile.sub,
                                google_auth_id_token: this.googleAuth
                            })
                        })
                        .then(res => {
                            //Convert to JSON in case we've received a string response
                            if (typeof res === 'string') {
                                res = JSON.parse(res);
                            }
                            //Check for an error response (status of "NOK")
                            if (res.status === 'NOK' || res.result.success === false) {
                                this.setState({
                                    showErrorMessage: true,
                                    errorMessage: res.result.error
                                });
                            } else {
                                // Log them in and Reload the window
                                this.props.loginSocialMediaUser(res, 'google');
                                window.scrollTo(0,0);
                                window.setTimeout(()=> {
                                    window.location.reload();
                                }, 1);
                            }
                        });
                    });
                }
            });
        }
    };

    successfulGoogleConnection = async (credentialResponse) => {
        //set state, determine login or register and call func below.
        await this.setState({
            loading: true
        }, async () => {
            this.googleProfile = decode(credentialResponse.credential);
            this.googleAuth = credentialResponse;
            await this.checkStudentExistsGoogle(this.googleProfile, this.googleAuth);
        });
    };

    checkStudentExistsGoogle = async (googleProfile, googleAuth) => {
        //API Request
        await api.post("students/checkStudentExists", {
            body: JSON.stringify({
                email: googleProfile.email
                //      + (config.environment === 'production' ? '' : Math.random().toString(36).replace(/[^a-z]+/g, ''))
                ,
                google_user_id: googleProfile.sub,
                google_auth_id_token: googleAuth,
                getData: true
            })
        })
        .then(data => {
            if (data.result.access_token) {
                // User already Connected with Google.
                // Log them straight in if they're trying to log in.
                if (this.props.context === 'Log In') {
                    this.props.loginSocialMediaUser(data, 'google');
                } else {
                    // If they're trying to register, reset their status and then log them in.
                    Modal.error({
                        icon: false,
                        title: false,
                        content: this.welcomeBack,
                        onOk: () => {
                            // Empty cart / bookings
                            this.props.basicStateReset(() => {
                                // Log them in and Redirect to home screen
                                this.props.loginSocialMediaUser(data, 'google');
                                window.scrollTo(0,0);
                                window.setTimeout(()=> {
                                    window.location.reload();
                                }, 1);
                            });
                        }
                    });
                }
            } else if (data.result.exists) {
                if (data.result.students.length === 1 && data.result.students[0].google_user_id !== googleProfile.sub) {
                    // Email Exists and is NOT attached to Google ID
                    this.setState({
                        loading: false,
                        singleUser: data.result.students[0]
                    }, () => {
                        this.modalToggle('singleUser');
                    });
                } else if (data.result.students.length > 1) {
                    // Multiple Students found with that email
                    this.setState({
                        loading: false,
                        multipleUsers: data.result.students
                    }, () => {
                        this.modalToggle('multipleUsers');
                    });
                } else {
                    // Email exists, but no unconnected students
                    Modal.error({
                        title: 'Unable to connect to Google Account.',
                            content: (
                                <span>
                                    Sorry - we were unable to connect your Google account to a student profile.<br />
                                    Please try again later, or contact us if you require assistance.
                                </span>
                            ),
                        icon: false,
                        closable: false,
                        keyboard: false,
                        okButtonProps: {
                            type: "primary"
                        },
                        onOk: () => {
                            this.setState({
                                loading: false
                            });
                        }
                    });
                }
            } else {
                if (this.props.context === 'Log In') {
                    // Neither Google ID or Email Exist
                    this.setState({
                        loading: false
                    }, () => {
                        this.modalToggle('noUser');
                    });
                } else {
                    // Create New Google Student
                    this.createNewGoogleStudent();
                }
            }
        });
    };

    render() {
        return (
            <React.Fragment>
                <GoogleOAuthProvider clientId={config.googleClientId}>
                    <GoogleLogin
                        logo_alignment="center"
                        size="large"
                        text="continue_with"
                        width="350px"
                        onSuccess={(credentialResponse) => {
                            this.successfulGoogleConnection(credentialResponse);
                        }}
                        onError={() => {
                            // Do Nothing
                        }}
                        useOneTap
                    />
                </GoogleOAuthProvider>
                {this.state.loading && (
                    <Spinner type="mega" />
                )}
                <Modal
                    className="user-check-modal"
                    title={<h2>SELECT ACCOUNT</h2>}
                    visible={this.state.multipleUsersModalVisible}
                    onCancel={() => this.modalToggle('multipleUsers')}
                    footer={null}
                >
                    <p>
                        We have found multiple student accounts with that email address.<br />
                        Unfortunately we can only link ONE to your Google account.<br />
                        Please select an account from the list below.
                    </p>
                    
                    <Table
                        showHeader={false}
                        columns={[
                            {
                                title: 'Name',
                                key: 'name',
                                render: (text, record) =>  {
                                    return (record.first_name + ' ' + record.last_name);
                                }
                            },
                            {
                                title: 'Action',
                                key: 'action',
                                width: 15,
                                render: (text, record) => {
                                    return (
                                        <FontAwesomeIcon icon={faUserCheck} size="lg" />
                                    );
                                }
                            }
                        ]}
                        rowKey={record => record.id}
                        dataSource={this.state.multipleUsers}
                        pagination={false}
                        onRow={(record, rowIndex) => {
                            return {
                                onClick: event => this.selectUser(record)
                            };
                        }}
                    />

                    <p>
                        If you believe this to be an error, or the students listed above are the same person, please contact the office so that we can assist you.
                    </p>

                    <Button
                        type="default"
                        size="default"
                        onClick={() => this.modalToggle('multipleUsers')}
                    >
                        Cancel
                    </Button>
                </Modal>
                <Modal
                    className="user-check-modal"
                    title={<h2>Connect Account?</h2>}
                    visible={this.state.singleUserModalVisible}
                    onCancel={() => this.modalToggle('singleUser')}
                    footer={null}
                >
                    <p>
                        Your Google Account is not linked to a Student record.<br />
                        However, your email address exists in our system.<br /><br />
                        Would you like to link this Google account to student <b style={{fontSize:'inherit'}}>"{this.state.singleUser.first_name} {this.state.singleUser.last_name}"</b>?
                    </p>
                    
                    <Button
                        disabled={this.state.connecting}
                        loading={this.state.connecting}
                        type="primary"
                        size="default"
                        onClick={() => this.selectUser(this.state.singleUser)}
                    >
                        Yes
                    </Button>
                    <Button
                        disabled={this.state.connecting}
                        type="default"
                        size="default"
                        onClick={() => this.modalToggle('singleUser')}
                    >
                        Cancel
                    </Button>
                </Modal>
                <Modal
                    className="user-check-modal"
                    title={<h2>Been here before?</h2>}
                    visible={this.state.noUserModalVisible}
                    onCancel={() => this.modalToggle('noUser')}
                    footer={null}
                >
                    <p>
                        Your Google Account is not linked to a Student record, and we don't have your email address in our system.<br /><br />
                        Would you like to register a new account?<br /><br />
                        If you believe this to be an error, please contact the office so that we can assist you.
                    </p>
                    
                    <Button
                        disabled={this.state.savingStudent}
                        loading={this.state.savingStudent}
                        type="primary"
                        size="default"
                        onClick={() => {
                            // Create New Google Student
                            this.createNewGoogleStudent();
                        }}
                    >
                        Yes
                    </Button>
                    <Button
                        disabled={this.state.savingStudent}
                        type="default"
                        size="default"
                        onClick={() => this.modalToggle('noUser')}
                    >
                        Cancel
                    </Button>
                </Modal>
            </React.Fragment>
        );
    }
};

export default GoogleLoginButton;
