import React from 'react';

//Ant Design Elements
import Button from "antd/lib/button";
import Modal from "antd/lib/modal";
import Table from "antd/lib/table";

import config from '../../../config';
import api from '../../../helpers/api';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUserCheck } from '@fortawesome/free-solid-svg-icons';
import Spinner from '../../elements/Spinner';

class FacebookLoginButton extends React.Component {

    state = {
        multipleUsersModalVisible: false,
        multipleUsers: {},
        singleUserModalVisible: false,
        singleUser: {},
        noUserModalVisible: false,
        loading: false,
        connecting: false,
        savingStudent: false,
        facebookProfile: null
    }

    componentDidMount() {
        return new Promise(resolve => {
            // wait for facebook sdk to initialize before starting the react app
            window.fbAsyncInit = function () {
                window.FB.init({
                    appId: config.facebookAppId,
                    cookie: true,
                    xfbml: true,
                    version: 'v10.0'
                });

                // auto authenticate with the api if already logged in with facebook..
                window.FB.getLoginStatus(({ authResponse }) => {
                    if (authResponse) {
                        // Residual Facebook token, despite us having no local knowledge.
                        // Rather than anything funky here to auto-login (because we'd have to check token expiry, etc)
                        // Let's just cleanly log out the FB user and pretend we saw nothing.
                        window.FB.logout();
                        // Result will just present the user with the normal login modal
                        resolve();
                    } else {
                        // Do nothing, not yet connected with Facebook
                        resolve();
                    }
                });
            };

            // load facebook sdk script
            (function (d, s, id) {
                var js, fjs = d.getElementsByTagName(s)[0];
                if (d.getElementById(id)) { return; }
                js = d.createElement(s); js.id = id;
                js.src = "https://connect.facebook.net/en_US/sdk.js";
                fjs.parentNode.insertBefore(js, fjs);
            }(document, 'script', 'facebook-jssdk'));
        });
    }

    modalToggle = (modal_name) => {
        this.setState({
            [modal_name + "ModalVisible"]: !this.state[modal_name + "ModalVisible"]
        });
    };

    /**
     * Create a new Student account with returned FB details.
     * The Student Refresh script will then populate everything else we need.
     */
    createNewFacebookStudent = () => {
        let values = this.state.facebookProfile;
        values.source = 'app';

        return api.post("students/save/facebook", {
            body: JSON.stringify(values)
        })
        .then(res => {
            //Convert to JSON in case we've received a string response
            if (typeof res === 'string') {
                res = JSON.parse(res);
            }
            //Check for an error response (status of "NOK")
            if (res.status === 'NOK' || res.result.success === false) {
                this.setState({
                    showErrorMessage: true,
                    loading: false
                });
            } else {
                // Save the access token
                this.props.cookies.set(config.environmentShort + 'AT', res.result.access_token, {path: '/', domain: config.siteDomain});
                // Update state
                this.props.setAppStateValue('user', {id: res.result.student_id});
                this.props.sendPageviewToGoogleAnalytics('/virtual/bookings-new/user-details/login'); //First ping to signal login
                this.props.sendEventToGoogleTagManager({
                    'event': 'user-signup-facebook'
                });
                // Smartlook - Track User Registration with Facebook
                if (window.location.hostname === 'app.ltrent.com.au' && typeof(smartlook) !== 'undefined' && typeof(smartlook) === 'function') {
                    /*global smartlook*/ // This line prevents the below throwing an error when not on production
                    smartlook('track', 'user', {
                        "type": "registration-facebook"
                    });
                }

                this.props.setAppStateValue('facebookUserId', values.facebook_user_id, false, () => {
                    this.props.setAppStateValue('connectedWithFacebook', true);
                });

                // Start Student TimeStamp Polling
                this.props.startStudentTimeStampChecker();
            }
        })
        .catch(error => {
            this.setState({
                showErrorMessage: true,
                loading: false
            });
        });

    };

    login = async() => {
        this.props.toggleSocialLoginCheck();
        let checkStudentExistsFacebook = this.checkStudentExistsFacebook;
        let toggleSocialLoginCheck = this.props.toggleSocialLoginCheck;

        window.FB.login(function(response) {
            // handle the response
            if (!response || response.authResponse === null) {
                //@todo: show an error?
                console.log('User closed  FB login modal');
                toggleSocialLoginCheck();
                return;
            }

            //Get data
            window.FB.api('/me?fields=id,email,first_name,last_name', function(response) {
                if (!response) {
                    //@todo: show an error
                    return;
                }
                // Store in State
                let facebookProfile = {
                    first_name:         response.first_name,
                    last_name:          response.last_name,
                    email:              response.email
                                        // + (config.environment === 'production' ? '' : Math.random().toString(36).replace(/[^a-z]+/g, ''))
                                        ,
                    facebook_user_id:   response.id
                };

                // Attempt to Login or Register.
                return checkStudentExistsFacebook(facebookProfile);
            });
        }, {scope: 'public_profile,email'});
    }

    checkStudentExistsFacebook = async (user) => {
        await this.setState({
            facebookProfile: user
        }, async () => {
            //API Request
            await api.post("students/checkStudentExists", {
                body: JSON.stringify({
                    email: user.email,
                    facebook_user_id: user.facebook_user_id,
                    getData: true
                })
            })
            .then(data => {
                if (data.result.access_token) {
                    // User already Connected with Facebook.
                    // Log them straight in if they're trying to log in.
                    if (this.props.context === 'Log In') {
                        this.props.loginSocialMediaUser(data, 'facebook');
                    } else {
                        // If they're trying to register, reset their status and then log them in.
                        Modal.error({
                            icon: false,
                            title: false,
                            content: this.welcomeBack,
                            onOk: () => {
                                // Empty cart / bookings
                                this.props.basicStateReset(() => {
                                    // Log them in and Redirect to home screen
                                    this.props.loginSocialMediaUser(data, 'facebook');
                                    this.props.redirect('/book/lesson/');
                                });
                            }
                        });
                    }
                } else if (data.result.exists) {
                    if (data.result.students.length === 1 && data.result.students[0].facebook_user_id !== user.facebook_user_id) {
                        // Email Exists and is NOT attached to Facebook ID
                        this.setState({
                            loading: false,
                            singleUser: data.result.students[0]
                        }, () => {
                            this.modalToggle('singleUser');
                        });
                    } else if (data.result.students.length > 1) {
                        // Multiple Students found with that email
                        this.setState({
                            loading: false,
                            multipleUsers: data.result.students
                        }, () => {
                            this.modalToggle('multipleUsers');
                        });
                    } else {
                        // Email exists, but no unconnected students
                        Modal.error({
                            title: 'Unable to connect to Facebook Accounts.',
                            content: (
                                <span>
                                    Sorry - we were unable to connect your Facebook account to a student profile.<br />
                                    Please try again later, or contact us if you require assistance.
                                </span>
                            ),
                            icon: false,
                            closable: false,
                            keyboard: false,
                            okButtonProps: {
                                type: "default"
                            },
                            onOk: () => {
                                this.setState({
                                    loading: false
                                });
                            }
                        });
                    }
                } else {
                    if (this.props.context === 'Log In') {
                        // Neither Facebook ID or Email Exist
                        this.setState({
                            loading: false
                        }, () => {
                            this.modalToggle('noUser');
                        });
                    } else {
                        // Create New Facebook Student
                        this.createNewFacebookStudent();
                    }
                }
            });
        });
    };

    welcomeBack = (
        <div>
            <p style={{fontWeight: 'bold'}}>Welcome back!</p>
            <p>To ensure that we re-connect you to your previous driver trainer, and that your personalised experience is guaranteed, we need to clear your reservations.</p>
            <p>If you are having trouble logging in or registering, please <a href={config.publicSiteUrl + "/locations-and-contact-us/"} target="_blank" rel="noreferrer">contact our office</a>.</p>
        </div>
    );

    // Selecting a User from found Facebook Users list
    selectUser = async (record) => {
        if (this.props.context === 'Log In') {
            await this.setState({connecting: true}, async () => {
                // Connect the User to this Facebook Login, then login as the user
                await api.post("students/connectFacebook",{
                    body: JSON.stringify({
                        student_id: record.id,
                        email: this.state.facebookProfile.email,
                        facebook_user_id: this.state.facebookProfile.facebook_user_id
                    })
                })
                .then(res => {
                    //Convert to JSON in case we've received a string response
                    if (typeof res === 'string') {
                        res = JSON.parse(res);
                    }
                    //Check for an error response (status of "NOK")
                    if (res.status === 'NOK' || res.result.success === false) {
                        this.setState({
                            showErrorMessage: true,
                            errorMessage: res.result.error
                        });
                    } else {
                        this.props.loginSocialMediaUser(res, 'facebook');
                    }
                });
            });
        } else {
            // If they're trying to register, reset their status and then log them in.
            Modal.error({
                icon: false,
                title: false,
                content: this.welcomeBack,
                onOk: async () => {
                    // Empty cart / bookings
                    this.props.basicStateReset(async () => {
                        // Connect the User to this Facebook Login, then login as the user
                        await api.post("students/connectFacebook", {
                            body: JSON.stringify({
                                student_id: record.id,
                                email: this.state.facebookProfile.email,
                                facebook_user_id: this.state.facebookProfile.facebook_user_id
                            })
                        })
                        .then(res => {
                            //Convert to JSON in case we've received a string response
                            if (typeof res === 'string') {
                                res = JSON.parse(res);
                            }
                            //Check for an error response (status of "NOK")
                            if (res.status === 'NOK' || res.result.success === false) {
                                this.setState({
                                    showErrorMessage: true,
                                    errorMessage: res.result.error
                                });
                            } else {
                                // Log them in and Reload the window
                                this.props.loginSocialMediaUser(res, 'facebook');
                                window.scrollTo(0,0);
                                window.setTimeout(()=> {
                                    window.location.reload();
                                }, 1);
                            }
                        });
                    });
                }
            });
        }
    };

    render() {
        return (
            <React.Fragment>
                <Button
                    disabled={this.props.disabled}
                    className="social-login-button"
                    size="default"
                    onClick={() => {
                        this.login();
                    }}
                >
                    <img
                        loading="lazy"
                        src={process.env.REACT_APP_SITE_URL + "/img/icons/facebook.svg"}
                        alt="Facebook Logo"
                    />
                    Continue with Facebook
                </Button>
                {this.state.loading && (
                    <Spinner type="mega" />
                )}
                <Modal
                    className="user-check-modal"
                    title={<h2>SELECT ACCOUNT</h2>}
                    visible={this.state.multipleUsersModalVisible}
                    onCancel={() => this.modalToggle('multipleUsers')}
                    footer={null}
                >
                    <p>
                        We have found multiple student accounts with that email address.<br />
                        Unfortunately we can only link ONE to your Facebook account.<br />
                        Please select an account from the list below.
                    </p>
                    
                    <Table
                        showHeader={false}
                        columns={[
                            {
                                title: 'Name',
                                key: 'name',
                                render: (text, record) =>  {
                                    return (record.first_name + ' ' + record.last_name);
                                }
                            },
                            {
                                title: 'Action',
                                key: 'action',
                                width: 15,
                                render: (text, record) => {
                                    return (
                                        <FontAwesomeIcon icon={faUserCheck} size="lg" />
                                    );
                                }
                            }
                        ]}
                        rowKey={record => record.id}
                        dataSource={this.state.multipleUsers}
                        pagination={false}
                        onRow={(record, rowIndex) => {
                            return {
                                onClick: event => this.selectUser(record)
                            };
                        }}
                    />

                    <p>
                        If you believe this to be an error, or the students listed above are the same person, please contact the office so that we can assist you.
                    </p>

                    <Button
                        type="default"
                        size="default"
                        onClick={() => this.modalToggle('multipleUsers')}
                    >
                        Cancel
                    </Button>
                </Modal>
                <Modal
                    className="user-check-modal"
                    title={<h2>Connect Account?</h2>}
                    visible={this.state.singleUserModalVisible}
                    onCancel={() => this.modalToggle('singleUser')}
                    footer={null}
                >
                    <p>
                        Your Facebook Account is not linked to a Student record.<br />
                        However, your email address exists in our system.<br /><br />
                        Would you like to link this Facebook account to student <b style={{fontSize:'inherit'}}>"{this.state.singleUser.first_name} {this.state.singleUser.last_name}"</b>?
                    </p>
                    
                    <Button
                        disabled={this.state.connecting}
                        loading={this.state.connecting}
                        type="primary"
                        size="default"
                        onClick={() => this.selectUser(this.state.singleUser)}
                    >
                        Yes
                    </Button>
                    <Button
                        disabled={this.state.connecting}
                        type="default"
                        size="default"
                        onClick={() => this.modalToggle('singleUser')}
                    >
                        Cancel
                    </Button>
                </Modal>
                <Modal
                    className="user-check-modal"
                    title={<h2>Been here before?</h2>}
                    visible={this.state.noUserModalVisible}
                    onCancel={() => this.modalToggle('noUser')}
                    footer={null}
                >
                    <p>
                        Your Facebook Account is not linked to a Student record, and we don't have your email address in our system.<br /><br />
                        Would you like to register a new account?<br /><br />
                        If you believe this to be an error, please contact the office so that we can assist you.
                    </p>

                    <Button
                        disabled={this.state.savingStudent}
                        loading={this.state.savingStudent}
                        type="primary"
                        size="default"
                        onClick={() => {
                            // Create New Facebook Student
                            this.createNewFacebookStudent();
                        }}
                    >
                        Yes
                    </Button>
                    <Button
                        disabled={this.state.savingStudent}
                        type="default"
                        size="default"
                        onClick={() => this.modalToggle('noUser')}
                    >
                        Cancel
                    </Button>
                </Modal>
            </React.Fragment>
        );
    }
}
;

export default FacebookLoginButton;
